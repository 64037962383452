// Theme Customizations to Navigations //

nav{
  clear: both;
}

.caret{
  display: none;
}

#navbar .nav > li{
  position: relative;
  margin-right: 20px;
  padding-right: 20px;
}

#navbar .nav > li > a{
  padding: 10px 0 !important;
}


.navbar-toggle{
  clear: both;
}

#navbar{
  padding-top: 20px;
  margin-bottom: 0;
}

#navbar .navbar-header{
  margin: 0 auto;
}

.navbar-brand{
  float: none;
  padding: 0;
  line-height: normal;
  height: auto;
  margin-left: auto !important;
}

@media (min-width: $screen-md-min) {
  .navbar-brand{
    float: left;
  }
}

@media (min-width: $screen-lg-min) {

  .navbar-collapse{
    padding: 10px 0;
  }
  nav{
    display: block;
  }

  .navbar-nav{
    list-style: disc outside none;
    display: flex;
    justify-content: center;
    float: none;
    border-top: 1px solid rgba(225, 225, 225, 0.5);
    padding: 5px 0 10px 0;
    margin-top: 95px;
    ul{
        width: auto !important;
        max-width: inherit !important;
      }
  }

  .navbar-nav > li > a::after{
    content: '\2022';
    margin-left: 20px;
    color: rgba(225, 225, 225, 0.3);
    font-size: 1.3em;
    position: absolute;
    right: -30px;
    bottom: 25%;
  }

  .navbar-nav > li.last > a::after{
    display: none;
  }

}
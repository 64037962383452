// Uncategorized Theme Customizations //

@font-face {
  font-family: 'amatic';
  src: url(../fonts/AmaticSC-Regular.ttf);
}


@font-face {
  font-family: 'lato';
  src: url(../fonts/Lato-Regular.ttf);
}

/* Colours */
$green: #84c22d;
$darkgreen: #005826;
$darkred: #990000;
$lightred: #cc0000;
$yellow: #ffcc00;
$grey: #dfdfdf;
$lightgrey: #cccccc;
$midgrey: #333333;
$darkgrey: #666666;
$brown: #d8b08c;
$lightbrown: #edcdb4;
$darkbrown: #8e6048;
$textbrown: #452505;
$intropara: #693415;
$bronze: #c69853;

.page-header {
  color: $darkred;
  text-transform: uppercase;
  padding: 5px 0;
  margin: 20px 0;
  font-family: 'amatic', serif;
  border-bottom: none;
}

h2, h3 {
  font-size: 26px;
  font-family: 'amatic', serif;

}

h3{
  color: $intropara;
  margin-bottom: 20px;
}

header {
  text-transform: uppercase;
  font-family: 'amatic', serif;
}

body {
  font-family: lato, Arial, sans-serif;
  line-height: 1.6em;
  letter-spacing: .05em;
  color: $darkgrey;
}

a {
  color: $green;
}

a:hover, a:focus {
//  color: $darkred;
}


svg{
  fill: inherit;
  stroke: inherit;
}

a svg{ transition: all .2s ease-in-out; }
a svg:hover{ transform: scale(.9); }

.logged-in #top-bar a svg:hover{fill: $yellow; stroke: $yellow !important;}

.logged-in #key, .not-logged-in #lock{
  display: none;
}

.icon{
  fill: #fff;
  stroke: #fff;
}

#block-system-main .field-name-body p:first-of-type{
  font-size: 18px;
}

#top-bar{
  background-color: $darkgreen;
  text-align: right;
  padding: 15px 0 5px 0;
  svg{
    margin-left: 40px;
    fill: #fff;
  }
  svg#house{
    fill: $green;
  }
  svg#mobile{
    stroke: #fff;
  }
  svg#key{
    stroke: #fff;
    stroke-width: 14px;
  }
  svg#beacon{
    stroke: #fff;
    stroke-width: 6px;
  }
}

.front h1.page-header{
  display: none;
}
.front h1.frontpage{
  display: block;
}
.node-type-news h1.page-header{
  display: none;
}

.node-type-news .newstitle{
  font-size: 48px;
  color: $darkgreen;
  font-weight: bold;
  margin-bottom: 0;
}

.node-type-news {
  .field-name-body p {
    margin: 40px 0;
    line-height: 2em;
  }
  .gallerythumbs h2.block-title, .newstitle{
    margin: 0;
  }
}

.node-type-news p:first-of-type{
  line-height: 1.5em;
}

.node-type-news .date-display-single{
  font-weight: bold;
  color: #000;
}

.main-container.container {
  margin-bottom: 20px;
  padding-bottom: 20px;
}

#headwrap {
  background-color: $green;
}

.container .jumbotron{
  padding: 40px 0 0;
  background-color: transparent;
  margin-bottom: 0;
}

.backlink{
  font-size: 2em;
  border: 2px solid rgb(204, 204, 204);
  border-radius: 5em;
  padding: 5px 12px;
  line-height: 1em;
}

.backlink:hover{
  text-decoration: none;
}

#footerwrap{
  background: $grey;
}


h2.block-title,
h1.page-header,
.backbutton{
  font-size:48px !important;
  color: $darkgrey;
  text-align: center;
  overflow: hidden;
  text-transform: capitalize;
  padding: 10px 0;
  font-weight: bold;
  letter-spacing: 5px;
}

.backbutton{
  font-size: 14px !important;
  margin-top: 20px;
}

h2.block-title::before,
h2.block-title::after,
h1.page-header::before,
h1.page-header::after,
.backbutton a::before,
.backbutton a::after
{
  background-color: $lightgrey;
  content: "";
  display: inline-block;
  height: 2px;
  position: relative;
  vertical-align: baseline;
  width: 50%;
}

.backbutton a::before,
.backbutton a::after{
  vertical-align: middle;

}

footer h2.block-title::before,
footer h2.block-title::after{
  background-color: $darkgrey;
}

h2.block-title::before,
h1.page-header::before,
.backbutton a::before
{
  right: 0.5em;
  margin-left: -50%;
}
h2.block-title::after,
h1.page-header::after,
.backbutton a::after{
  left: 0.5em;
  margin-right: -50%;
}

footer .linedbox{
  color: #fff;
}

footer .webform-client-form label{
  color: $brown;
  font-size:24px;
  font-family: amatic, serif;
  font-weight: normal;
  text-transform: uppercase;
}

footer .webform-client-form .form-control{
  background-color: transparent;
  border: 1px solid $brown;
  border-radius: 0;
}

footer .webform-client-form button{
  background-color: $brown;
  font-size: 24px;
  font-family: amatic,serif;
  color: rgb(71, 81, 54);
  width: 100%;
  border: 0;
}


footer .webform-client-form .webform-component--message{
  float: right;
}

footer .contactus{
  text-align: center;
  .cont1{
    font-family: 'amatic', serif;
    font-size: 2em;
    line-height: 1.2em;
    font-weight: bold;
    svg{
      fill: $green;
      margin: 10px 0;
    }
  }

  .cont2 svg{
    fill: #ffffff;
    stroke: #ffffff;
  }
  .cont2 a{
    background-color: $green;
    margin: 0 10px;
    padding: 10px 8px 5px 8px;
    display: inline-block;
    border-radius: 2em;
  }
}

#block-views-dorset-gazetter-block{
  color: $green;
  overflow: hidden;
  text-align: center;
  .view-content{
    border-top: 1px solid #666;
    padding-top: 20px;
    margin-top: 40px;
  }
}

.gallerythumbs h2.block-title{
  color: $green;
}

#bottombarwrap{
  margin-top: 40px;
  color: #fff;
  padding: 10px;
}

#footleft{
  float: left;
}
#footright{
  float: right;
  a{
    color: #e8b245;
  }
}

.conticons {
  .icon {
    display: inline-block;
    border: 2px solid rgba(225, 225, 225, .3);
    padding: 12px 12px 6px;
    margin: 10px;
    border-radius: 2em;
  }
  .facebk {
    text-align: center;
    margin-right: 0;
  }
}

.dgt_pager{
  font-family: amatic, serif;
  font-size: 20px;
  .pager-last, .pager-first{
    display: none;
  }
  li{
    display: inline-block;
    margin-right: 10px;
  }
  li a{
    background-color: transparent;
    color: $lightred;

  }
  li a:hover{
    background-color: $lightred;
    color: #fff;
  }
  li span, li a{
    padding: 6px 15px;
  }
  li.active span{
    background-color: $lightred;
    border: 1px solid $lightred;
    border-radius: 0;
  }
  li.active span:hover{
    background-color: $lightred;
  }
}
.dgt_pager .text-center{
  text-align: center;
}

#navbar{
  a.navbar-brand{
    font-size: 60px;
    color: #fff;
    letter-spacing:5px;
  }
  .lead{
    font-size: 42px;
    color: $darkgreen;
    margin-top: 10px;
    letter-spacing: 5px;
    text-transform: capitalize;
  }
}

#navbar .nav {
  li a {
    color: $darkgreen;
    font-size: 18px;
    padding: 5px 20px;
    font-family: lato, sans-serif;
    text-transform: capitalize;
  }
  li a:hover {
    color: #fff;
  }
  li a.active {
    background-color: transparent;
  }
  li.last a {
    border-right: 0;
  }
  li.open {
    a {
      background-color: transparent;
    }
    ul {
      background-color: $green;
      a{
        font-size: 15px;
        text-indent: 5px;
      }
    }
  }

}

.flexslider {
  margin: 0;
  border-radius: 0;
  border: 0;
}

.flex-caption{
  background-color: rgba(0, 0, 0, .6);
  color: #fff;
  font-family: 'amatic', serif;
  font-size: 3em;
  line-height: 1.4em;
}

.page_header, .member_page_header{
  background: url("/sites/all/themes/custom/swcaa/images/pageheader.jpg")repeat-x;
  min-height: 196px;
  .flex-caption{
    position: relative;
    display: block;
    top: 20px;
    left: 0;
    font-size: 40px;
    text-align: center;
    line-height: .5em;
    padding: 20px 0;

    .sub{
      font-size: 23px;
    }
    .bull{
      color: $green;
    }
  }
}

.member_page_header{
  background: url("/sites/all/themes/custom/swcaa/images/memberspageheader.jpg")repeat-x;
  .flex-caption .bull{
    color: $yellow;
  }
}

/* 3 column view */
.cols-3 .view-content,
.cols-5 .view-content,
.fp {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border-top: 1px solid $brown;
  margin-top: 20px;
}

.fp > * {
  width: 100%;
 // max-width: 420px;
}

/* Front page layout */
.front .region-content {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  .sliderblock {
    header {
      display: none;
    }
  }
  .block-dgt-frontblock {
    h2.block-title {
      display: none;
    }
  }
  #block-system-main {
    width: 100%;
  }

  .fpintro {
    color: #693415;
    h2 {
      border-top: 1px solid $brown;
      border-bottom: 1px solid $brown;
      padding: 10px 0 20px;
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  .fpbox {
    background-color: $darkbrown;
    padding: 10px;
    color: #fff;
    .fpbox-inner {
      padding: 20px;
      border: double $brown;
    }
  }

}

.fpitemwrap header, .view-events header, .contwrap, .findus{
  h2{
    overflow: hidden;
    text-align: center;
    color: $lightred;
  }

  h2::before, h2::after{
    background-color: $darkred;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  h2:before {
    right: 0.5em;
    margin-left: -50%;
  }
  h2:after {
    left: 0.5em;
    margin-right: -50%;
  }

}

.view-events .views-field-title, .cols-5 .views-field-field-garden-name{
  font-family: amatic, serif;
  font-size: 24px;
  border-bottom: 1px solid $brown;
  margin-bottom: 10px;
  padding-bottom: 10px;
}




.morearrow {
  display: block;
  margin: 15px auto 0;
}




/** News/Resource Item gallery **/
.gallerythumbs .view{
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    li {
      list-style: none;
      margin-bottom: 40px;
    }
  }
}

#edit-submit-dorset-gazetter{
  background-color: $green;
  border-radius: 0;
  font-size: 24px;
  font-family: Georgia, Times, serif;
}

#views-exposed-form-dorset-gazetter-block{
  label{
    font-family: Georgia, Times, serif;
    font-weight: normal;
    font-size:24px;
    text-transform: uppercase;
  }

  input{
    background-color: transparent;
    border: 1px solid $green;
    border-radius: 0;
  }

  .views-submit-button{
    clear: both;
  }
}
/**
.schoolsevents h2{
  color: $darkred !important;
  font-size: 48px;
}

.schoolsevents .view-content,
.page-events .view-content{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  border: none;

  .views-row{
    @extend .linedbox;
    margin-bottom: 30px;
    max-width: 295px;
    .views-field-title{
      border-bottom: 1px solid $brown;
      margin-bottom: 10px;
      padding-bottom: 10px;
    }
  }
}

.page-events .views-exposed-form{
  font-size: 20px;
  font-family: amatic, serif;
  .views-widget{
    float: none;
  }
  label{
    text-transform: uppercase;
    font-weight: normal;
    float: left;
    color: $intropara;
    margin-right: 10px;
  }
  .form-item{
    float: none;
    > .form-item{
      border: none;
    }
    > :last-child{
      border-right: none;
    }

  }
}

.cols-5 .view-content > div{
  max-width: 255px;
}
**/

/** Hack to fix flexbox last row **/
.view-events .view-content .dummy,
.view-dorset-gazetter .view-content .dummy{
  height: 0;
  padding: 0;
  margin: 0;
  border: none;
  flex: 1 0 100%;
}
/** End Hack **/

.view-events > .view-content{
  padding: 0;
}

.fp .view-events > .view-content{
  padding: 20px;
}

.contwrap{
  text-align: center;
  font-family: 'amatic', serif;
  font-size: 30px;
  line-height: 1em;
  font-weight:bold;
  svg{
    fill: $green;
  }
}

/** Line through title **/
.linethrough{
//  font-size:48px !important;
//  color: $brown;
  text-align: center;
  overflow: hidden;
 // text-transform: uppercase;
//  padding: 10px 0;
}

.linethrough:before
{
  right: 0.5em;
  margin-left: -50%;
}
.linethrough:after
{
  left: 0.5em;
  margin-right: -50%;
}

.field-name-field-boxes > .field-items,
#node-11 {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

#node-11:before{
  content: none;
}

.field-name-field-boxes > .field-items > .field-item,
#node-11 > .paragraphs-item-call-to-action {
  margin: 10px 0;
  max-width: 260px;
  padding: 0;
}

.field-name-field-title,
.field-name-field-sub-heading{
  background-color: $green;
  max-width: 260px;
}

.field-name-field-title{
  color: #fff;
  text-align: center;
  font-family: 'amatic', serif;
  font-size: 36px;
  padding: 20px 0;
}
.field-name-field-title .field-item::before,
.field-name-field-title .field-item::after{
  background-color: $darkgreen;
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
//  vertical-align: middle;
  width: 20%;
}


.field-name-field-title .field-item::before
{
  right: 0.5em;
  margin-left: -50%;
}
.field-name-field-title .field-item::after
{
  left: 0.5em;
  margin-right: -50%;
}

.buttbase{
  padding: 10px 20px;
  color: #fff;
  border-radius: 1em;
  display: block;
  margin-bottom: 20px;
  clear: both;
  text-align: center;
}

.downloadsbox .field-name-field-title .field-item::before,
.downloadsbox .field-name-field-title .field-item::after{
  width: 30%;
}
.downloadsbox .field-name-field-title{
  max-width: 100%;
  background-color: transparent;
  color: $darkgreen;
  padding: 0 0 45px;
  line-height: .8em;
}

.downloadsbox{
  font-family: 'amatic', serif;
  font-size: 2em;
  .buttonsbox, .formsbox{
    max-width: 260px;
    float: left;
  }
  .buttonsbox{
    margin-right: 20px;
  }
  .formstxt{
      font-size: 1.7em;
      line-height: 0.77em;
      font-weight: bold;
      margin-bottom: 77px;
  }
  .greenbut{
    @extend .buttbase;
    background-color: $green;
  }
}

.field-name-field-sub-heading{
  color: $darkgreen;
  padding-left: 20px;
  padding-bottom: 14px;
}

#block-views-news-block{
  padding: 0;
}

#block-views-news-block-1 {
  padding-left: 0;
  .view-content{
    flex-flow: column;
  }
  .views-row{
    margin-bottom: 20px;
  }
}

.view-content{
  ul {
    padding: 0;
    li {
      list-style: none;
      padding: 0;
    }
  }
}

.mempic{
  padding:0 20px 0 0 !important;
  min-height: 260px;
}

.memtitpos{
  padding:0 !important;
  color: $midgrey;
  .memtit{
    font-size: 30px;
    line-height: 1.2em;
  }
  .mempos{
    font-size: 18px;
    margin-top: 13px;
  }
}

.contdetails svg {
  fill: $green;
  margin: 10px;
}

.abtboxwrap{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  > div{
    margin-bottom: 10px;
    overflow: hidden;
  }
}

.oldernews .views-row{
  min-width:260px;
}

/* members area */
.swcaa_member{
  a{color: $lightred;}
  #top-bar{
    background-color: $darkred;
    svg#house{
      fill: $yellow;
    }
  }
  #headwrap{
    background-color: $lightred;
  }
  #navbar .lead, #navbar li a{
    color: $yellow;
  }

  #navbar li.open ul{
    background-color: $lightred;
  }

  .contactus .cont1 svg{
    fill: $darkred !important;
  }

  .contactus .cont2 a{
    background-color: $darkred;
  }

  h2.restitle{
    font-size: 26px;
    font-family: inherit;
  }

  .gallerythumbs h2.block-title{
    color: $lightred;
  }

  a.downres{
    @extend .buttbase;
    background-color: $lightred;
    display: inline-block;
    width:auto;
    font-family: amatic, serif;
    font-size: 2em;
    margin-top: 20px;
  }

  #block-views-members-resources-block .views-row a{
    @extend a.downres;
    width: 100%;
    margin-top: 0;
  }
}

#block-webform-client-block-15 .form-control{
  border: 1px solid $green;
  border-radius: 30px;
}

#block-webform-client-block-15 .webform-submit {
  width: 100%;
  background-color: $green;
  border: none;
  border-radius: 30px;
  font-size: 3em;
  line-height: 1em;
  .req{
    font-size: .4em;
    border-top: 1px solid #fff;
    padding-top: 10px;
  }
}

.swcaa-sitemap ul{
  list-style: none;
  padding: 0;
  font-family: amatic, serif;
  font-size: 2em;
  margin-bottom: 40px;
  text-align: center;
  line-height:1em;
  li{
    display: inline-block;
    border-right: 1px solid $darkgrey;
    padding-right: 5px;
    margin-right: 5px;
  }
  li.last{
    border-right: 0;
    padding-right: 0;
    margin-right: 0;
  }
}

.oldernews .pager,
.membernews .pager,
.memberresources .pager,
.frontnews .pager{
  background: $lightgrey;
  height: 1px;
  .pager-current{
    display: none;
  }
  .pager-previous, .pager-next{
    margin-top: -22px;
    display: inline-block;
    .ajax-progress{
      display: none;
    }
  }
  li > a{
    display: inline-block;
    padding: 0 10px 12px 12px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 35px;
    font-size: 4em;
    line-height: .5em;
  }
}

.membernews .pager, .memberresources .pager{
  li a:hover{
    background: $lightgrey;
  }
}

#gmap-auto2map-gmap0{
  height: 300px !important;
}

@media (min-width: $screen-sm-min) {

  .node-type-page .field-type-image {
    float: right;
    margin-left: 10px;
  }

  .front .region-content .fpintro h2 {
    font-size: 36px;
  }

  .morearrow {
    width: 50%;
  }

  /* 5 column view */
  .cols-5 .view-content > div{
    width: 49%;
    margin: 10px 0;
  }

  .cols-5 .view-content::after{
    content: '';
    width:49%;
  }

  .navbar-toggle{
    margin-right: 35px;
  }

  #navbar{
    .lead{
      white-space: nowrap;
    }
  }
  .view-content {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    .views-row{
      padding: 3px;
  //    width: 260px;
      margin-bottom: 40px;
      .picspace{
        margin-bottom: 15px;
      }
    }
  }
  
  span.date-display-single{
    font-size: .9em;
  }

  .swcaa_member a.downres{
    width: 100%;
  }

  .page_header{
    .flex-caption{
      .sub {
        clear: both;
        display: block;
        margin-top: 10px;
      }
    }
  }

  .contdetails{
    font-size: 18px;
    margin-bottom: 20px;
  }

  #block-views-members-resources-block .views-row{
    width: 50%;
  }
}

@media (min-width: $screen-md-min) {

  /* 3 column view */
  .cols-3 .view-content .views-row,
  .fp > * {
    width: 49%;
  }
  .cols-3 .view-content::after,
  .fp > *::after {
    width: 49%;
  }

  #headwrap {
    min-height: 145px;
  }
/*
  #navbar .nav {
    float: right;
    position: absolute;
    bottom: -20px;
    li a{
      border-right: 1px solid #9a826d;

    }

  }
*/
  #navbar .container {
    padding: 0;
  }
  .navbar-toggle{
    margin-right: 55px;
  }

  h1.page-header {
    font-size: 46px;
  }

  h3{
    font-size: 30px;
  }

  .front .region-content .sliderblock, .front .region-content .block-dgt-frontblock {
    width: 100%;
  }

  /**
  article.node-garden, article.node-event {
    font-size: 30px;
    .field-name-field-image{
      float: right;
    }
  }
**/
    /* 5 column view */

  /**
    .cols-5 .view-content > div{
      width: 30%;
      margin: 10px 0;
    }

    .cols-5 .view-content::after{
      content: '';
      width:30%;
    }

    #views-exposed-form-dorset-gazetter-block .views-exposed-widgets{
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      color: $green;

      label{
        font-weight:normal;
      }

      .views-exposed-widget{
        width: 30%;
      }
      .views-submit-button{
        margin: 0 auto;
        button{
          width:100%;
        }
      }
    }
    #views-exposed-form-dorset-gazetter-block .views-exposed-widgets::before{
      display: none;
    }

  .page-events .views-exposed-form {
    .views-widget{
      float: left;
    }
    .form-item{
      float: left;
      > .form-item{
        border-right: 1px solid $intropara;
        margin-right: 15px;
        padding-right: 15px;
      }
    }
    .form-item:last-child{
      border-right: none;
    }
  }
**/
  #contactuspage{
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    border-bottom: 2px solid $lightgrey;
    margin-bottom: 25px;
    padding-bottom: 25px;
  }
  .contwrap{
    width: 30%;
    .linedbox{
      font-size: 28px;
      #contemail{
        word-wrap: break-word;
      }
    }
  }

  .contdetails{
    display: flex;
    justify-content: space-between;

    > div{
      text-align: center;
    }
    svg{
      fill: $green;
      margin-bottom: 20px;
    }
  }
  .swcaa_member a.downres{
    width: 50%;
  }

  .swcaa_member #block-views-members-resources-block .views-row {
    width: 31%;
    margin-bottom:0;
    a {
      width: 100%;
    }
  }
}

  @media (min-width: $screen-lg-min) {

    /* 3 column view */
    .cols-3 .view-content,
    .contwrap,
    .fp > * {
      width: 30%;
      display: flex;
      flex-direction: column;
    }

    .contwrap h2{
      height: 70px;
    }

    .view-events .view-content, .linedbox{
      height: 100%;
    }

    .cols-3 .view-content::after,
    .fp > *::after {
      content: '';
      width: 30%;
    }

    /* 5 column view */
    .cols-5 .view-content > div{
   //   width: 19%;
      margin: 10px 0;
    }

    .cols-5 .view-content::after{
      content: '';
      width: 20%;
    }

    .front .page-header {
      font-size: 66px;
      margin: 20px 0 20px;
 //     display: none;
    }

    h1.page-header {
      font-size: 66px;
    }

    h2 {
      font-size: 36px;
    }

    .front {
      .block-dgt-frontblock {
        width: 30% !important;
        h2.block-title {
          display: block !important;
          text-transform: uppercase;
          color: $darkred;
          font-size: 66px;
          margin-top: -10px;
        }
      }
      .region-content .sliderblock {
        width: 66% !important;
      }
      .region-content .fpintro h2 {
        font-size: 42px;
      }
      .main-container{
//        border-top: 1px solid $brown;
        padding-top: 30px;
      }
    }
    .morearrow {
      width: 100%;
    }
    .page-events .views-exposed-form {
      font-size: 30px;
    }

    .findus h2{
      font-size: 48px;
      text-transform: uppercase;
      color: $darkred;
    }
    .dgt_pager{
      margin-top: -100px;
      .text-center{
        text-align: right;
      }
    }
    .view-dorset-gazetter .dgt_pager{
      margin-top: auto;
    }

    #navbar a.navbar-brand {
      margin-right: 80px;
    }

    .page-events .view-content .views-row, .view-dorset-gazetter .linedbox{
      height: auto;
    }

    .conticons{
      float: right;
    }

    .flex-caption{
      position: absolute;
      bottom: 30%;
      left: 50%;
      font-size: 5em;
      padding: 30px;
      line-height: .5em;
      transform: translateX(-50%);
      line-height: 1em;
    }

    .view-content .views-row{
        padding: 0;
    }

    #block-views-news-block-1 {
      padding-right: 0;
      padding-left: 40px;
    }

    .gallerythumbs{
      max-width: 48%;
      float: right;
      margin-left: 20px;
    }

    .swcaa_member a.downres{
      width: 40%;
    }

    .swcaa_member #block-views-members-resources-block .views-row a{
      width: 100%;
    }

    .page_header, .member_page_header{
      .flex-caption{
        width: 720px;
        font-size: 72px;
        margin: 0 auto;
        height:50%;
        letter-spacing: .14em;
        line-height: .6em;
        .sub{
          font-size: 43px;
        }
      }
    }


    #block-webform-client-block-15 label{
      margin: 15px 0 25px;
    }
    #block-webform-client-block-15 .webform-submit{
      margin-top: 60px;
      height: 130px;
    }

    .frontnews{
      margin-bottom: 30px;
    }

    .membernews .pager, .frontnews .pager{
      position: absolute;
      width: 130%;
      z-index: 100;
      li a:hover{
        background: $lightgrey;
      }
    }

    .memberresources{
      margin-top: 20px;
    }
  }

  @media (min-width: 1160px) {
    .container {
      width: 1160px;
      padding: 0;
    }
  }

  .navbar-default {
    background-color: transparent;
    border-color: transparent;
  }

//Display breakpoints
/*
@media (min-width: $screen-sm-min) {
  body:after {
    content: "Small";
  }
}
@media (min-width: $screen-md-min) {
  body:after {
    content: "Medium";
  }
}
@media (min-width: $screen-lg-min) {
  body:after {
    content: "Large";
  }
}
*/